<template>
  <div id="editDialog">
    <el-dialog
      title="新增账号"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="80px"
      >
        <el-form-item label="车牌号" prop="plateNum">
          <el-input
            v-model="addForm.plateNum"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="车辆大小" prop="vehicleSize">
          <el-select v-model="addForm.vehicleSize" placeholder="请选择车辆大小">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item of select_vehicleSize"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆品牌">
          <el-input
            v-model="addForm.vehicleBrand"
            placeholder="请输入车辆品牌"
          ></el-input>
        </el-form-item>
        <el-form-item label="车辆颜色">
          <el-input
            v-model="addForm.vehicleColor"
            placeholder="请输入车辆颜色"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      addForm: {
        plateNum: "",
        vehicleSize: "",
        vehicleBrand: "",
        vehicleColor: "",
      },
      addFormRules: {
        plateNum: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
        vehicleSize: [
          { required: true, message: "请选择车辆大小", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$set(this, "addForm", {
        plateNum: "",
        vehicleSize: "",
        vehicleBrand: "",
        vehicleColor: "",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/vehicle/add", {
          ...this.addForm,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#editDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>